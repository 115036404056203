/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.3
*/


.owl-theme .owl-controls{
	text-align: center;
}


.owl-theme .owl-controls .owl-buttons{
	bottom: 50%;
    margin-bottom: -25px;
    position: absolute;
    text-align: center;
    width: 100%;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div{
	color: #FFF;
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	margin: 5px;
	padding: 3px 10px;
	font-size: 25px;
	
}

.owl-theme .owl-controls .owl-buttons  .owl-prev{
	float:left;
}


.owl-theme .owl-controls .owl-buttons  .owl-next{
	float:right;
}


/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
	text-decoration: none;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span{
	display: block;
	width: 5px;
	height: 5px;
	margin: 10px 3px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background: #eaeaea;
	opacity:.6;
}

.owl-theme .owl-controls .owl-page.active span
{
	background: #cccccc;
	transform:scale(1.4);
	-webkit-transform: scale(1.4);
}


.owl-theme .owl-controls.clickable .owl-page:hover span{
	background: #cccccc;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
	min-height: 150px;
	background: url(AjaxLoader.gif) no-repeat center center
}