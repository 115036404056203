/*
*	DIFFERENCE (HTML version)
* 	Copyright 2016
* 	www.mutationmedia.net
* 	Created by : mutationthemes
*
*/



/* Table of Content
==================================================
1-Reset & Basics
2-Basic Styles
3-Typography
4-Links
5-Misc




/* Reset & Basics (Inspired by E. Meyers)*/

*, *:before, *:after {
    -moz-box-sizing: border-box;
	-webkit-box-sizing :border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}


* { margin:0; padding:0; }



	html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline; 
		text-decoration:none;}
	article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
		display: block; }

	ol, ul {
		list-style: none; }
	blockquote, q {
		quotes: none; }
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none; }
	table {
		border-collapse: collapse;
		border-spacing: 0; }


a, a:hover, a:active, a:visited, a:focus {
    text-decoration: none;
}


img {
    height: auto;
    max-width: 100%;
	display: inline-block;
    vertical-align: middle;
}


#map img { max-width: none; }

*::-moz-selection {
    background: #199cdb;
    color: #ffffff;
}

a:focus {
outline: 0;
}

iframe{
	width:100%;
	frameborder:0;
	display:inline-block;
	vertical-align: bottom;
}
	


/* Basic Styles*/
	body {
		background: #ffffff;
		font: 14px/22px "open_sans",Arial,sans-serif;
		color: #777777;
		-webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
		-webkit-text-size-adjust: 100%;
		


		
 }


	h1, h2, h3, h4, h5, h6 {color: #333333;}
	em { font-style: italic; }
	strong {color:#333333;}
	small { font-size: 80%; }
	
	
	.uppercase{
		text-transform:uppercase;
	}
	
	
	.white{
		color:#ffffff;
	}
	

.spinner, .spinner:after, .exp:after , .edu:after, .hb-list ul li span, .thumbs li img, .thumbs li a span, .img-profile img, 
.comments .author-img img, .comments .author-img  {
	    border-radius: 100%;
		-moz-border-radius:100%;
		-webkit-border-radius :100%;
		-ms-border-radius:100%;
		-o-border-radius: 100%;
	
	}
	
	
		
	.overlay:before{
	    background: rgba(35, 35, 35, .6);
	    content: "";
	    height: 100%;
	    position: absolute;
	    width: 100%;
		left:0;
		top:0;
		z-index:0;
	}



	ul.list li{
		line-height:32px;
	}


	ul.list li a{
		color:#777777;
	}


	ul.list li a:before {
	    content: '\ea8f';
	    display: inline-block;
	    font-family: "fontello";
	 	font-size: 12px;
	    margin-right: 10px;
	}
	
	
	
	.block-background-image {
	    backface-visibility: hidden;
		background-attachment: fixed;
	    background-position: center center;
	    background-repeat: no-repeat;
	    background-size: cover;
	    box-sizing: border-box;
	    display: block;
	    overflow: visible;
	    position: relative;
	    width: 100%;
	}


	.block-background-image.large{
			padding: 200px 0;
	}
	
	
	.block-background-image.medium{
			padding: 100px 0;
	}
	
	


	.title{
		font-size:29px;
		position:relative;
	}


	.title.medium{
		font-size:28px;
	}	

	.title:before{
		content:"";
		position:absolute;
		background:#199cdb;
		height:2px;
		left:0;
		top: -20px;
		width: 30px;
	}
	
	.title.medium:before{
		width: 28px;
	}
	
	
	
	

/* Typography*/


	.block-teaser h1, .fact-list li .desc-fact span, .name-profile h5{
		font-family: 'titillium-semibold',Arial,sans-serif;
	}
	
	
	.menu li {
		font-family: 'titillium_bdbold',Arial,sans-serif;
	}
	
	
	
	.fact-list li .desc-fact, .testi-thumbs h3{
		font-family: 'titilliumthin_upright',Arial,sans-serif;
	}
	
	
	 .title{
		font-family: 'titilliumlight_upright',Arial,sans-serif;
	}
	
	
	.filter li, .comments .reply-btn a, .but  {
		font-family: "titilliumregular_upright",Arial,sans-serif;

	}
	
	
	.block-teaser h3, .name-profile h5 span, .block-about.one h5 , .block-table .table h6,
	.block-single h1.large-title, .socials.round li a, .testi-details .td blockquote{
		font-family: "open_sanslight",Arial,sans-serif;
	}
	
	
	
	 .block-price span.price, .post-details h4 a,.post-details h4, .block-about ul.info span, .exp-det h6, .exp .hgroup h4, .edu .hgroup h4, .ast-list ul li, .blog-widget h6, .author a, .serv-det h5, .info-det h5, .block-feed h6, .skill-list h4 {
	    font-family: "open_sansbold",Arial,sans-serif;
	}
	
	
	.exp .hgroup h6 , .edu .hgroup h6 {
	    font-family: "open_sansitalic",Arial,sans-serif;
	}
	
	
	.testi-details .td h6, .item a .desc h5, strong, .post-meta li.date  {
		font-family: "open_sanssemibold",Arial,sans-serif;
	}
	
	.testi-details .td span, .block-price  span.per {
		font-family: "open_sansregular",Arial,sans-serif;
	}
	
	.item a .desc  span {
		font-family: "open_sanslight_italic",Arial,sans-serif;
	}


/*Links*/
	
	a, .t-active span,.desc, .item img, .contact-form .submit, .reply-form .submit,input, textarea, .insta-Feed li a:before{
		
		transition: all 0.4s ease-in-out 0s;
		-ms-transition: all 0.4s ease-in-out 0s;
		-webkit-transition:all 0.4s ease-in-out 0s;
		-moz-transition: all 0.4s ease-in-out 0s;
		-o-transition: all 0.4s ease-in-out 0s;
		
		
	}
	
	
	.but{

		padding:7px 40px;
		display:inline-block;
		border-radius: 20px;
		-moz-border-radius:20px;
		-webkit-border-radius :20px;
		-ms-border-radius:20px;
		-o-border-radius: 20px;
		font-size:15px;

	}


	.but.ico i{
		position:relative;
		left:-12px;
	}


	.but.opc{

		background:#199cdb;	
	}


	.but.opc:hover{

		background:#ffffff;	
		color:#199cdb;
	}


	.but.brd{
		background:none;
		border:2px solid #199cdb;
		color:#199cdb;
	}


	.but.brd:hover{
		background:#199cdb;	
		color:#ffffff;
	}
	
	
	

/*Misc*/
	
	.pt-10{padding-top:10px !important;} 
	.pb-10{padding-bottom:10px !important;}
	.pt-15{padding-top:15px !important;} 
	.pb-15{padding-bottom:15px !important;}
	.pt-20{padding-top:20px !important;} 
	.pb-20{padding-bottom:20px !important;}
	.pt-30{padding-top:30px !important;} 
	.pb-30{padding-bottom:30px !important;}
	.pt-40{padding-top:40px !important;} 
	.pb-40{padding-bottom:40px !important;}
	.pt-50{padding-top:50px !important;}
	.pb-50{padding-bottom:50px !important;}
	.pt-60{padding-top:60px !important;}
	.pb-60{padding-bottom:60px !important;}
	.pt-70{padding-top:70px !important;}
	.pb-70{padding-bottom:70px !important;}
	.pt-80{padding-top:80px !important;}
	.pb-80{padding-bottom:80px !important;}
	.pt-100{padding-top:100px !important;}
	.pb-100{padding-bottom:100px !important;}
	.pt-160{padding-top:160px !important;}
	.pb-160{padding-bottom:160px !important;}
	.mt-5{margin-top:5px !important;}
	.mb-5{margin-bottom:5px !important;}
	.mt-10{margin-top:10px !important;}
	.mb-10{margin-bottom:10px !important;}
	.mt-15{margin-top:15px !important;}
	.mb-15{margin-bottom:15px !important;}
	.mt-20{margin-top:20px !important;}
	.mb-20{margin-bottom:20px !important;}
	.mt-30{margin-top:30px !important;}
	.mb-30{margin-bottom:30px !important;}
	.mt-40{margin-top:40px !important;}
	.mb-40{margin-bottom:40px !important;}
	.mt-50{margin-top:50px !important;}
	.mb-50{margin-bottom:50px !important;}
	.mt-60{margin-top:60px !important;}
	.mb-60{margin-bottom:60px !important;}
	.mt-70{margin-top:70px !important;}
	.mb-70{margin-bottom:70px !important;}
	.mt-80{margin-top:80px !important;}
	.mb-80{margin-bottom:80px !important;}
	.mt-90{margin-top:90px !important;}
	.mb-90{margin-bottom:90px !important;}
	.mt-100{margin-top:100px !important;}
	.mb-100{margin-bottom:100px !important;}
	.mt-150{margin-top:150px !important;}
	.mb-150{margin-bottom:150px !important;}
	.mt-200{margin-top:200px !important;}
	.mb-200{margin-bottom:200px !important;}
	.t-center {text-align: center;}
	.t-left {text-align: left;}
	.t-right {text-align: right;}
	.bg-grey{background:#f7f7f7;}
	.bg-grey-dark{background:#333333;}
	.bg-grey-1{background:#f5f5f5;}
	.bg-grey-2{background:#f0f0f0;}
	.bg-white{background:#ffffff;}
	.bg-blue{background:#199cdb;}
	.pd-0{padding:0 !important;}

